<template>
    <CRow>
        <CCol col="12" xl="12">
            <CCard>
                <CCardHeader>
                    <CCol col="6" style="font-size: 25px;">تعديل اللون</CCol>
                </CCardHeader>
                <CCardBody>
                    <div>
                        <div class="row">
                            <div class="col-md-6">
                                <form @submit.prevent="UpdateColor">
                                    <div class="form-group">
                                        <label style="padding:5px;">Name <span class="star">*</span></label>
                                        <input type="text" class="form-control" v-model="color.name" placeholder="الاسم باللغة الإنجليزية" required>
                                        <div v-if(errors.name) id="validateName" class="invalid-feedback">
                                          {{errors.name}}
                                        </div>
                                    </div>

                                      <div class="form-group">
                                        <label style="padding:5px;">الاسم <span class="star">*</span></label>
                                        <input type="text" class="form-control" v-model="color.name_ar" placeholder="الاسم باللغة العربية" required>
                                    </div>

                                    <div class="form-group">
                                        <label style="padding:5px;">اللون <span class="star">*</span></label>
                                        <input type="color" class="form-control" v-model="color.color_code" required>
                                        <div v-if(errors.color_code) id="validateColorCode" class="invalid-feedback">
                                          {{errors.color_code}}
                                        </div>
                                    </div>
                                    <button type="submit" class="btn btn-success" >تحديث البيانات</button>
                                    &emsp;
                                    <CButton color="warning" @click="goBack">رجوع</CButton>
                                </form>
                            </div>
                        </div>
                    </div>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
import swal from 'sweetalert'
export default {
  name : 'EditColor',
  data() {
    return {
        color: [],
        image:'',
        errors: []
    }
  },
  created() {
    console.log(this.$route.params.id);
    this.$http
      .get(`${process.env.VUE_APP_URL}colors/${this.$route.params.id}/edit`)
      .then((response) => {
          this.color = response.data.data;
      });
  },
  methods: {
    UpdateColor() {
      let currentObj = this;
      let formData = new FormData();
      formData.append('name', this.color.name);
      formData.append('name_ar', this.color.name_ar);
      formData.append('color_code', this.color.color_code);
      formData.append('_method', 'PUT');

      this.$http
        .post(`${process.env.VUE_APP_URL}colors/${this.$route.params.id}`,
          formData,
          {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
          })
        .then((response) => {
          if (!response.data.error) {
            swal({ title:response.data.message, buttons: "تم"});
            currentObj.$router.push({ path: "/colors" });
          } else {
            currentObj.errors = response.data.data;
          }
      });
    },
    goBack() {
      this.colorsOpened ? this.$router.go(-1) : this.$router.push({path: '/colors'})
    }
  }
}
</script>